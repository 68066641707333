
	import iconfont from '../components/iconfont.vue';
	import * as echarts from 'echarts';
	import {
		getCookie
	} from '../util/index.js';
	export default {
		name: 'Home',
		components: {
			iconfont
		},
		methods: {
			fliter(){
				let newarr = [];
				for(let i = 0;i<this.salesdata.length;i++){
					let name = this.salesdata[i].name;
					if(name.indexOf(this.shop))
						newarr.push(this.salesdata[i]);
				}
			},
			toggle(){
				if(this.rank == 2){
					this.salesdata.reverse();
					this.rank = 1;
				}
				else{
					this.rank = 2;
					this.salesdata.reverse();
				}
					
			},
			GetWorkbench() {
				this.axios.get(this.actions.homedata, {
					headers: {
						"Content-Type": "application/json",
					},
					params: {
						subop: '',
						top: 50,	//最近50条操作日志
						storeid:this.storeid
					}
				}).then((response) => {
					console.log("档案");
					console.log(response.data);
					if(response.data.retinfo == 'ok') {
						this.mylogs = response.data.logs;
						this.stat = response.data.stat;
						this.profile = response.data.profile;
						if(this.profile.HeadImage) {
							this.HeadImage = this.apibase + this.profile.HeadImage;
							this.$emit("onHeadImageChanged", this.HeadImage);
						}
						
						this.totalmoney = response.data.info.totalmoney;
						this.today = response.data.today;
						this.week = response.data.week;
						this.month = response.data.month;
						this.year = response.data.year;
						this.ordercount = response.data.info.ordercount;
						this.deal = response.data.info.deal;
						this.retreat = response.data.info.retreat;
					}
				})
			},
			formatDate(date) {
				var datetime = new Date(date * 1000);
				var year = datetime.getFullYear();
				var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
				var day = ("0" + datetime.getDate()).slice(-2);
				
				var hours = ("0" + datetime.getHours()).slice(-2);
				var minutes = ("0" + datetime.getMinutes()).slice(-2);
				var seconds = ("0" + datetime.getSeconds()).slice(-2);
				
				return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
			},
			drawline(){
				let datacharts = echarts.init(document.getElementById("datacharts"));
				
				let ratiocharts = echarts.init(document.getElementById("ratiocharts"));
				
				let columncharts = echarts.init(document.getElementById("columncharts"));
				
				console.log(datacharts);
				console.log(ratiocharts);
				console.log(columncharts);
				let base = + new Date(2020, 8, 3);
				let oneDay = 24 * 3600 * 1000;
				let data1 = [[base, Math.random() * 3000]];
				let data2 = [[base, Math.random() * 5000]];
				for (let i = 1; i < 20000; i++) {
					let now = new Date((base += oneDay));
					data1.push([+now, Math.round((Math.random() - 0.5) * 20 + data1[i - 1][1])]);
					data2.push([+now, Math.round((Math.random() - 0.5) * 50 + data2[i - 1][1])]);
				}
				datacharts.setOption({
					legend:{
						data:['销售吊牌数量','分配吊牌数量']
					},
					tooltip: {
						trigger: 'axis',
						position: function (pt) {
							return [pt[0], '10%'];
						}
					},
					toolbox: {
						feature: {
							
							saveAsImage: {}
						}
					},
					xAxis: {
						type: 'time',
						boundaryGap: false
					},
					yAxis: {
						type: 'value',
						boundaryGap: [0, '100%']
					},
					dataZoom: [
						{
							type: 'inside',
							start: 0,
							end: 20
						},
						{
							start: 0,
							end: 20
						}
					],
					series: [
						{
							name: '销售吊牌数量',
							type: 'line',
							stack: "x",
							smooth: true,
							symbol: 'none',
							data: data1
						},
						{
							name: '分配吊牌数量',
							type: 'line',
							stack: "x",
							smooth: true,
							symbol: 'none',
							data: data2
						}
					]
				});
				ratiocharts.setOption({
					legend:{
						top:"bottom"
					},
					series : [
						{
							name: '销售类型占比',
							type: 'pie',
							radius: '55%',
							roseType: 'angle',
							data:[
								{value:235, name:'玩具'},
								{value:274, name:'箱包'},
								{value:310, name:'成人装'},
								{value:335, name:'鞋'},
								{value:400, name:'童装'}
							],
							label:{
								show:true,
								position:'outside',
								formatter:'{b}:{d}%'
							}
						}
					]
				});
				columncharts.setOption({
					title:{
						text:'申请吊牌数量(单位：万)'
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						containLabel: true
					},
					xAxis: {
						type: 'value',
						boundaryGap: [0, 0.01]
					},
					yAxis: {
						type: 'category',
						data: ['叮当猫叮当专卖店', '叮当猫卡夏尔专卖店', '叮当猫雨樱专卖店', '叮当猫圣瑞熙专卖店', '叮当猫奥斯町专卖店', '叮当猫豪泰专卖店','叮当猫千本居专卖店','叮当猫DDCAT阳瑞专卖店','叮当猫深之福专卖店','叮当猫斯塔克专卖店']
					},
					series:[{
						name:'数量单位(万)',
						type:'bar',
						data:[15, 9, 7, 7, 10, 8, 4, 2,4,6]
					}]
				});
				
			},
			
		},
		data() {
			return {
				totalmoney:'',
				today:"",
				week:"",
				month:"",
				year:"",
				ordercount:"",
				deal:"",
				goodcount:"",
				retreat:"",
				roleid:'',
				chartsarr:[],
				shop:'',
				rank:1,
				mylogs: [],
				stat: {},
				profile: {},
				HeadImage: '',
				msgdata:[
					{
						title:'叮当猫叮当专卖店',
						status:'已授权',
						time:"2022-06-29",
						timestamp: '2021-12-20 10:45:13'
					},
					{
						title:'叮当猫卡夏尔专卖店',
						status:'已授权',
						time:"2022-08-31",
						timestamp: '2021-12-20 10:45:13'
					},
					{
						title:'叮当猫雨樱专卖店',
						status:'已授权',
						time:"2022-09-10",
						timestamp: '2021-12-20 10:45:13'
					},
					{
						title:'叮当猫圣瑞熙专卖店',
						status:'已授权',
						time:"2022-09-10",
						timestamp: '2021-12-20 10:45:13'
					},
					{
						title:'叮当猫奥斯町专卖店',
						status:'已授权',
						time:"2022-09-06",
						timestamp: '2021-12-20 10:45:13'
					}
				],
				salesdata:[
					{
						name:"叮当猫叮当专卖店",
						ratio:75,
					},
					{
						name:"叮当猫卡夏尔专卖店",
						ratio:72,
					},
					{
						name:"叮当猫雨樱专卖店",
						ratio:71,
					},
					{
						name:"叮当猫圣瑞熙专卖店",
						ratio:61,
					},
					{
						name:"叮当猫奥斯町专卖店",
						ratio:55,
					},
					{
						name:"叮当猫豪泰专卖店",
						ratio:52,
					},
					{
						name:"叮当猫千本居专卖店",
						ratio:47,
					},
					{
						name:"叮当猫DDCAT阳瑞专卖店",
						ratio:30,
					},
					{
						name:"叮当猫深之福专卖店",
						ratio:19,
					},
					{
						name:"叮当猫斯塔克专卖店",
						ratio:8,
					}
				],
				storeid:'',
			}
		},
		beforeUnmount(){
			if(this.roleid != 12){
				console.log("销毁之前");
				echarts.init(document.getElementById("datacharts")).dispose();
				echarts.init(document.getElementById("ratiocharts")).dispose();
				echarts.init(document.getElementById("columncharts")).dispose();
				console.log("销毁");
			}
			
		},
		beforeCreate() {
			this.PAGELANG = this.LANG.pages.Home;
		},
		mounted() {
			if(this.roleid != 12){
				
				this.drawline();
			}
			this.GetWorkbench();
			//window.addEventListener("resize",this.chartResize);
		},
		created:function(){
			this.roleid = getCookie("roleid");
			let storeid = getCookie("storeid");
			if(storeid)
				this.storeid = storeid;
		}
	}
